<template>
  <div class="settings container is-fullhd">
    <h1 class="title">Settings</h1>
    <section class="columns">
      <div class="column is-three-quarters">

        <h2 class="title is-5">Preferences</h2>
        <h3 class="subtitle is-7">Settings for SketchDaily</h3>

        <b-field
          label="Favourite colour"
          message="Select your favourite colour, it will be the default when you start any sketch.">
          <b-input
            v-model="favouriteColour"
            type="color"
            name="favouriteColour"
            class="color-picker"/>
        </b-field>

        <h2 class="title is-5 mt-6">Social</h2>
        <h3 class="subtitle is-7">Publicly visible social and contact information</h3>

        <SocialSettings />

        <h2 class="title is-5 mt-6">Email preferences</h2>
        <h3 class="subtitle is-7">Choose if and when you'd like to be emailed by us</h3>

        <div class="field">
          <b-switch v-model="streakEmails">Receive streak reminders by email</b-switch>
        </div>

        <div class="field">
          <b-switch v-model="promoEmails">Receive news and updates by email</b-switch>
        </div>

        <h2 class="title is-5 mt-6">Account</h2>
        <h3 class="subtitle is-7">Account and security settings</h3>

        <div class="field">
          <b-switch v-model="showPasswordForm">Change password</b-switch>
        </div>

        <template v-if="showPasswordForm">
          <b-field label="Current password">
            <b-input
              v-model="currentPassword"
              type="password"
              name="current-password"
              autocomplete="current-password" />
          </b-field>
          <b-field label="New password" message="At least 8 characters in length.">
            <b-input
              v-model="password"
              type="password"
              name="new-password"
              autocomplete="new-password" />
          </b-field>
          <b-field label="Repeat password">
            <b-input
              v-model="repeatPassword"
              type="password"
              name="favouriteColour"
              autocomplete="new-password" />
          </b-field>
          <b-button @click.stop="changePassword" :loading="submittingPassword" type="is-primary">
            Save
          </b-button>
        </template>
      </div>

      <Sidebar class="column" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import API from '@/services/api';
import Sidebar from '@/components/Sidebar.vue';
import SocialSettings from '@/components/SocialSettings.vue';

export default {
  name: 'Settings',
  components: {
    Sidebar,
    SocialSettings,
  },
  data() {
    return {
      initialised: false,

      fcTO: 0,
      favouriteColour: '#000000',
      streakEmails: false,
      promoEmails: false,

      showPasswordForm: false,
      submittingPassword: false,
      currentPassword: '',
      password: '',
      repeatPassword: '',
    };
  },
  created() {
    this.favouriteColour = `#${this.user.favouriteColour}`;
    this.streakEmails = this.user.getsStreakEmails;
    this.promoEmails = this.user.getsPromoEmails;
    this.$nextTick(() => {
      this.initialised = true;
    });
  },
  computed: {
    ...mapGetters({
      user: 'session/user',
    }),
  },
  watch: {
    favouriteColour(val) {
      const value = val.replace('#', '');
      if (!this.initialised) return;
      if (this.fcTO > 0) clearTimeout(this.fcTO);
      this.fcTO = setTimeout(() => {
        this.updateSetting('favouriteColour', value).then(() => {
          this.$store.commit('session/updateUserProperty', { name: 'favouriteColour', value });
        });
        this.fcTO = 0;
      }, 500);
    },
    streakEmails(value) {
      if (!this.initialised) return;
      this.updateSetting('getsStreakEmails', value).then(() => {
        this.$store.commit('session/updateUserProperty', { name: 'getsStreakEmails', value });
      });
    },
    promoEmails(value) {
      if (!this.initialised) return;
      this.updateSetting('getsPromoEmails', value).then(() => {
        this.$store.commit('session/updateUserProperty', { name: 'getsPromoEmails', value });
      });
    },
  },
  methods: {
    async updateSetting(name, value) {
      try {
        await API.users.updateSetting({ name, value });
        this.$toasted.show('Setting updated!', { duration: 2000 });
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
    async changePassword() {
      if (this.submittingPassword) return;
      this.submittingPassword = true;
      try {
        await API.users.changePassword({
          currentPassword: this.currentPassword,
          password: this.password,
          repeatPassword: this.repeatPassword,
        });
        this.$toasted.show('Password updated!', { duration: 2000 });
        this.showPasswordForm = false;
        this.currentPassword = '';
        this.password = '';
        this.repeatPassword = '';
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.submittingPassword = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.color-picker {
  max-width: 350px;
}
</style>
