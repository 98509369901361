<template>
  <div class="social-settings">
    <ul class="mb-4">
      <li
        v-for="(social, i) in user.social"
        :key="i"
        :style="{display: editing && editing.id === social.id ? 'none' : ''}"
        class="social-row">
        <div class="level is-mobile">
          <div class="level-left">
            <span class="icon" :style="{color: `#${socialById(social.socialId).color}`}">
              <fa-icon :icon="[
                socialById(social.socialId).iconPack,
                socialById(social.socialId).icon,
              ]" />
            </span>
            <a
              :href="socialURL(social)"
              target="_blank"
              rel="nofollow noopener">
              {{social.content}}
            </a>
          </div>
          <div class="level-right">
            <b-button @click.stop="toggleSocialForm(social)" size="is-small">
              <fa-icon :icon="['fas', 'edit']"/>
            </b-button>
            <b-button @click.stop="deleteSocial(social.id)" type="is-danger" size="is-small">
              <fa-icon :icon="['fas', 'trash-alt']"/>
            </b-button>
          </div>
        </div>
      </li>
    </ul>
    <form v-if="showForm" @submit.prevent="submitForm">
      <div class="field has-addons">
        <div class="control">
          <div class="select">
            <select v-model="selectedSocial">
              <option
                v-for="(s, i) in availableSocialTypes"
                :key="i"
                :value="s">
                {{s.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="control is-expanded">
          <b-input
            v-model="content"
            type="text"
            name="content"
            :placeholder="placeholder"/>
        </div>
        <div class="control">
          <b-button
            native-type="submit"
            type="is-primary"
            :disabled="submitting"
            :class="{'is-loading': submitting}">
            <template v-if="editing">Update</template>
            <template v-else>Add</template>
          </b-button>
        </div>
        <div class="control">
          <b-button @click.stop="toggleSocialForm" :disabled="submitting">
            Cancel
          </b-button>
        </div>
      </div>
    </form>
    <b-button
      v-else
      @click.stop="toggleSocialForm"
      :disabled="availableSocialTypes.length === 0">
      Add
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import API from '@/services/api';

export default {
  name: 'SocialSettings',
  data() {
    return {
      showForm: false,
      submitting: false,
      selectedSocial: null,
      content: '',
      editing: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'session/user',
      socialTypes: 'site/social',
      socialById: 'site/socialById',
    }),

    placeholder() {
      if (!this.selectedSocial) return '';
      return this.selectedSocial.placeholder ? this.selectedSocial.placeholder : '';
    },
    availableSocialTypes() {
      return this.socialTypes.filter((st) => {
        for (let i = 0; i < this.user.social.length; i += 1) {
          const ust = this.user.social[i];
          if (ust.socialId === st.id && (!this.editing || this.editing.socialId !== st.id)) {
            return false;
          }
        }
        return true;
      });
    },
  },
  methods: {
    socialURL(social) {
      const sd = this.socialById(social.socialId);
      const prefix = sd.urlPrefix ? sd.urlPrefix : '';
      return `${prefix}${social.content}`;
    },
    toggleSocialForm(data) {
      if (this.showForm) {
        this.showForm = false;
        this.editing = null;
        this.content = '';
        return;
      }

      if (data && data.id) { // editing
        this.editing = data;
        this.content = data.content;
        const socialType = this.socialTypes.filter((s) => s.id === data.socialId);
        // eslint-disable-next-line prefer-destructuring
        this.selectedSocial = socialType[0];
      } else {
        if (this.availableSocialTypes.length === 0) return;
        // eslint-disable-next-line prefer-destructuring
        this.selectedSocial = this.availableSocialTypes[0];
      }

      this.showForm = true;
    },
    submitForm() {
      if (this.submitting) return;
      if (!this.selectedSocial) {
        this.$toasted.error('No type selected.', { duration: 2000 });
        return;
      }
      if (!this.content) {
        this.$toasted.error('Social content cannot be empty.', { duration: 2000 });
        return;
      }

      this.submitting = true;

      const data = {
        userId: this.user.id,
        socialId: this.selectedSocial.id,
        content: this.content,
      };

      if (this.editing) {
        data.id = this.editing.id;
        this.updateSocial(data);
        return;
      }

      this.addSocial(data);
    },
    async addSocial(data) {
      try {
        await API.users.social.create(data);
        await this.$store.dispatch('session/getProfile');
        this.toggleSocialForm();
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }

      this.submitting = false;
    },
    async updateSocial(data) {
      try {
        await API.users.social.update(data.id, data);
        await this.$store.dispatch('session/getProfile');
        this.toggleSocialForm();
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }

      this.submitting = false;
    },
    async deleteSocial(id) {
      if (this.submitting) return;
      const conf = window.confirm('Are you sure you want to delete this?'); // eslint-disable-line no-alert
      if (!conf) return;
      this.submitting = true;
      try {
        await API.users.social.delete(id);
        await this.$store.dispatch('session/getProfile');
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.submitting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.social-row {
  &:hover {
    background: rgba($color: #000000, $alpha: 0.05);
  }
}
</style>
